export default function Footer(){
    return (
        <div>
            <div style={{height:100,backgroundColor:'#f9f7f7',borderTop:'1px solid rgb(235, 233, 233)'}}>
                <p style={{textAlign:'center',color:'lightgray',paddingTop:40}}>© 2023 cfatih.dev | Tüm Hakları saklıdır</p>
            </div>
            <div style={{height:10,backgroundColor:'gray'}}></div>
        </div>
    )
}
